import React, { useState, useEffect } from "react";
import axios from "axios";
import setAuthToken from "../../utils/setAuthToken";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { PickerDropPane } from "filestack-react";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import FormLabel from "@material-ui/core/FormLabel";
import AddAlert from "@material-ui/icons/AddAlert";
import Icon from "@material-ui/core/Icon";

import Datetime from "react-datetime";
//Form
import CustomInput from "components/CustomInput/CustomInput.js";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Danger from "components/Typography/Danger";
import Snackbar from "components/Snackbar/Snackbar.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import GridItem from "components/Grid/GridItem";
import Slide from "@material-ui/core/Slide";
import SweetAlert from "react-bootstrap-sweetalert";
import Close from "@material-ui/icons/Close";
import InputAdornment from "@material-ui/core/InputAdornment";
import Email from "@material-ui/icons/Email";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import ImageSearchIcon from "@material-ui/icons/ImageSearch";

import logoBsci from "assets/img/boston-scientific-logo.png";

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
export default function CerrarTiquetes(props) {
  const initialState = {
    email: "",
    password: "",
    isSubmitting: false,
    errorMessage: null,
  };
  const API_KEY = "AWevYlMNkS7KJFbiRivemz";

  const [desde, setDesde] = useState(
    new Date().setDate(new Date().getDate() + 7)
  );
  const [files, setFiles] = useState([]);
  const [flag, setFlag] = useState("");
  const [estado, setEstado] = useState("");
  const [categoria, setCategoria] = useState("");
  const [urlFile, setUrlFile] = useState([]);
  const [urlFileError, setUrlFileError] = useState();
  const [numReporte, setNumReporte] = React.useState("");
  const [txtClave, setTxtClave] = React.useState("");
  const [errors, setErrors] = React.useState({});
  const [tc, setTC] = React.useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [alert, setAlert] = React.useState(null);
  const [tiquete, setTiquete] = useState();
  const [loginModal, setLoginModal] = React.useState(false);
  const [data, setData] = React.useState(initialState);
  const [appBody, setAppBody] = useState(0);
  const [textReporteTec, setTextReporteTec] = useState("");
  const [textMateriales, setTextMateriales] = useState("");
  const [textHorasHombre, setTextHorasHombre] = useState(0);
  const [textHorasExtra, setTextHorasExtra] = useState(0);
  const [contratista, setContratista] = useState();
  const [responseMessage, setResponseMessage] = useState();
  const [eliminarModal, setEliminarModal] = React.useState(false);

  const classes = useStyles();

  const showNotification = () => {
    setTC(true);
    setTimeout(function() {
      setTC(false);
    }, 6000);
  };

  //login
  const handleInputChange = (event) => {
    setData({
      ...data,
      [event.target.id]: event.target.value,
    });
  };

  //Login
  const verifyLogInSubmit = async () => {
    let errorLocal = {};
    !data.password
      ? (errorLocal.password = "Porfavor ingresar una clave válida")
      : setFlag(200);
    setErrors(errorLocal);
    if (Object.keys(errorLocal).length === 0) {
      await axios
        // .get(
        //   `http://localhost:5000/api/reporte/verificarContratista/${data.password}`
        // )
        .get(
          `https://epstool.com/api/reporte/verificarContratista/${data.password}`
        )
        .then((res) => {
          setLoginModal(false);
          setLoadingModal(true);
          setContratista(res.data);
          let dataToSend = { contratista: res.data, numReporte: numReporte };
          console.log(dataToSend);
          switch (estado) {
            case "Nuevo":
              axios
                // .post(
                //   `http://localhost:5000/api/reporte/solicitarAbrirTiquete`,
                //   dataToSend
                // )
                .post(
                  `https://epstool.com:5000/api/reporte/solicitarAbrirTiquete`,
                  dataToSend
                )
                .then((res) => {
                  setLoadingModal(false);
                  setResponseMessage(res.data.message);
                  showNotification();
                })
                .catch((error) => {
                  setResponseMessage(error.response.data);
                  showNotification();
                });
              break;
            case "En proceso":
              setAppBody(2);
              setLoadingModal(false);
              break;
            default:
              break;
          }
        })
        .catch((error) => {
          setErrors(error.response.data);
        });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let errorMessage = {};
    !numReporte
      ? (errorMessage.comentario = "Debe escribir el número del tiquete")
      : setFlag(200);
    // !tecnico
    // ? (errorMessage.tecnico = "Debe selecionar un técnico")
    // : setFlag(200);

    setErrors(errorMessage);

    if (Object.keys(errorMessage).length === 0) {
      setLoadingModal(true);
      await axios
      // .get(`http://localhost:5000/api/reporte/${numReporte}`)
      .get(`https://epstool.com/api/reporte/${numReporte}`)
        .then((res) => {
          switch (res.data.estado) {
            case 0:
              setEstado("Nuevo");
              break;
            case 1:
              setEstado("En proceso");
              break;
            case 2:
              setEstado("Terminado");
              break;
            default:
              break;
          }
          setTiquete(res.data);
          setAppBody(1);
          setLoadingModal(false);
        })
        .catch((error) => {
          setErrors(error.response.data);
        });
    }
  };

  const handleListFiles = async (uploadedFiles) => {
    uploadedFiles.forEach((element) => {
      console.log(element);

      setFiles((prevFiles) => [...prevFiles, element]);
    });
  };

  const handleDeleteFile = async (idFileToDelete) => {
    setFiles(files.filter((item) => item.uploadId !== idFileToDelete));
  };

  // GUARDAR REPORTE
  const handleReporteTecSubmit = async (event) => {
    let errorMessage = {};
    !textReporteTec
      ? (errorMessage.comentario = "Debe rellenar este campo")
      : setFlag(200);
    !textMateriales
      ? (errorMessage.materiales = "Debe rellenar este campo")
      : setFlag(200);
    !textHorasHombre
      ? (errorMessage.horasHombre = "Debe rellenar este campo")
      : setFlag(200);
    !textHorasExtra
      ? (errorMessage.horasExtra = "Debe rellenar este campo")
      : setFlag(200);
    files.length === 0
      ? (errorMessage.archivos = "Debe adjuntar una fotografía")
      : setFlag(200);
    setErrors(errorMessage);

    if (Object.keys(errorMessage).length === 0) {
      event.preventDefault();
      const reporteTecToSave = {
        asignado: tiquete.asignar[0].asignacion,
        otros: textReporteTec,
        userName: contratista.name,
        userCompany: contratista.company,
        materiales: textMateriales,
        horasHombre: textHorasHombre,
        horasExtra: textHorasExtra,
        archivos: files,
      };
      await axios
        .post(
          // `http://localhost:5000/api/reporte/reportetec/${numReporte}`,
          `https://epstool.com/api/reporte/reportetec/${numReporte}`,
          reporteTecToSave
        )
        .then((res) => {
          setTextReporteTec("");
          setTextMateriales("");
          setTextHorasHombre("");
          setTextHorasExtra("");
          setData(initialState);
          setFiles([]);
          setResponseMessage("Reporte técnico realizado con éxito");
          setEliminarModal(false);
          setAppBody(0);
          showNotification();
        })
        .catch((error) => {
          setErrors(error.response.data);
        });
    }
  };

  let bodySelector;
  switch (appBody) {
    case 0:
      bodySelector = (
        <GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={4} md={3}>
              <FormLabel className={classes.labelHorizontal}>
                Número de reporte:
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={8} md={9}>
              <CustomInput
                multiline
                labelText="# Reporte"
                id="numReporte"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: (event) => {
                    setNumReporte(event.target.value);
                  },
                  type: "number",
                  value: numReporte,
                }}
              />
              <Danger>
                {errors.numReporte && (
                  <div className="invalid-feedback">{errors.numReporte}</div>
                )}
              </Danger>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={0} md={2}></GridItem>
            <GridItem xs={12} md={4}>
              <Button fullWidth color="success" onClick={handleSubmit}>
                Buscar reporte
              </Button>
            </GridItem>
            {/* <GridItem xs={12} md={4}>
              <Button fullWidth color="success" onClick={handleSubmit}>
                Crear reporte
              </Button>
            </GridItem> */}
          </GridContainer>
        </GridContainer>
      );
      break;
    case 1:
      if (tiquete) {
        let adjuntos;
        if (!tiquete.files[0]) {
          adjuntos = <h4>No tiene archivos adjuntos</h4>;
        } else {
          adjuntos = (
            <ul>
              {tiquete.files.map((item, index) => (
                <li key={index}>
                  <a href={item} target="_blank" rel="noopener noreferrer">
                    Archivo {index + 1}
                  </a>
                </li>
              ))}
            </ul>
          );
        }
        bodySelector = (
          <GridContainer>
            <GridContainer>
              <GridItem xs={12} className={classes.center}>
                <h3>Tiquete {tiquete.codigoReporte}</h3>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={4} md={3}>
                Estado:
              </GridItem>
              <GridItem xs={8} md={9}>
                {estado}
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={4} md={3}>
                Asignado:
              </GridItem>
              <GridItem xs={8} md={9}>
                {tiquete.asignar[0]
                  ? tiquete.asignar[0].asignacion
                  : "No se ha asignado"}
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={4} md={3}>
                Detalle:
              </GridItem>
              <GridItem xs={8} md={9}>
                {tiquete.detalle}
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} md={3}>
                Archivos:
              </GridItem>
              <GridItem xs={12} md={9}>
                {adjuntos}
              </GridItem>
            </GridContainer>
            <br />
            <GridContainer>
              <GridItem xs={0} md={2}></GridItem>
              {tiquete.estado === 0 ? (
                <GridItem xs={12} md={4}>
                  <Button
                    fullWidth
                    color="success"
                    onClick={() => setLoginModal(true)}
                  >
                    Solicitar Asignación
                  </Button>
                </GridItem>
              ) : tiquete.estado === 1 ? (
                <GridItem xs={12} md={4}>
                  <Button
                    fullWidth
                    color="success"
                    onClick={() => setLoginModal(true)}
                  >
                    Cerrar tiquete
                  </Button>
                </GridItem>
              ) : (
                <GridItem xs={12} md={4}>
                  <Button
                    disabled
                    fullWidth
                    color="success"
                    onClick={() => setLoginModal(true)}
                  >
                    Tiquete finalizado
                  </Button>
                </GridItem>
              )}
              <GridItem xs={12} md={4}>
                <Button fullWidth color="danger" onClick={() => setAppBody(0)}>
                  Regresar
                </Button>
              </GridItem>
            </GridContainer>
          </GridContainer>
        );
      } else {
        setAppBody(0);
      }
      break;
    case 2:
      bodySelector = (
        <GridContainer>
          <GridContainer>
            <GridItem xs={12} className={classes.center}>
              <h3>Tiquete {tiquete.codigoReporte}</h3>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={4} md={3}>
              Materiales requeridos:
            </GridItem>
            <GridItem xs={8} md={9}>
              <CustomInput
                multiline
                // labelText=""
                id="textMateriales"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  multiline: true,
                  onChange: (event) => {
                    setTextMateriales(event.target.value);
                  },
                  type: "text",
                }}
              />
              <Danger>
                {errors.materiales && (
                  <div className="invalid-feedback">{errors.materiales}</div>
                )}
              </Danger>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={4} md={3}>
              Horas hombre naturales:
            </GridItem>
            <GridItem xs={8} md={9}>
              <CustomInput
                id="textHorasHombre"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: (event) => {
                    setTextHorasHombre(event.target.value);
                  },
                  type: "number",
                }}
              />
              <Danger>
                {errors.horasHombre && (
                  <div className="invalid-feedback">{errors.horasHombre}</div>
                )}
              </Danger>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={4} md={3}>
              Horas hombre extra:
            </GridItem>
            <GridItem xs={8} md={9}>
              <CustomInput
                id="textHorasExra"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: (event) => {
                    setTextHorasExtra(event.target.value);
                  },
                  type: "number",
                }}
              />
              <Danger>
                {errors.horasExtra && (
                  <div className="invalid-feedback">{errors.horasExtra}</div>
                )}
              </Danger>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={4} md={3}>
              Otros comentarios:
            </GridItem>
            <GridItem xs={8} md={9}>
              <CustomInput
                multiline
                // labelText="Escribir otros comentarios"
                id="textReporteTec"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  multiline: true,
                  onChange: (event) => {
                    setTextReporteTec(event.target.value);
                  },
                  type: "text",
                }}
              />
              <Danger>
                {errors.comentario && (
                  <div className="invalid-feedback">{errors.comentario}</div>
                )}
              </Danger>
            </GridItem>
          </GridContainer>
          <br />
          <GridContainer>
            <GridItem xs={12} sm={4} md={3}>
              <FormLabel className={classes.labelHorizontal}>
                Archivos:
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={8} md={9}>
              <PickerDropPane
                apikey={API_KEY}
                onUploadDone={(res) => handleListFiles(res.filesUploaded)}
                pickerOptions={{ maxFiles: 5 }}
                onError={(error) =>
                  setUrlFileError(
                    "Se ha producido un error, porfavor intentarlo nuevamente"
                  )
                }
              />
              <Danger>
                {errors.archivos && (
                  <div className="invalid-feedback">{errors.archivos}</div>
                )}
              </Danger>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={4} md={3}>
              <FormLabel className={classes.labelHorizontal}>
                Archivos Cargados:
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={8} md={9}>
              <List>
                {files.length === 0 ? (
                  <p className={classes.labelHorizontalIzq}>
                    No hay archivos cargados
                  </p>
                ) : (
                  files.map((file) => (
                    <ListItem dense key={file.uploadId}>
                      <ListItemText primary={file.filename} />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="open"
                          onClick={() => window.open(file.url, "_blank")}
                        >
                          <ImageSearchIcon />
                        </IconButton>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleDeleteFile(file.uploadId)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))
                )}
              </List>
            </GridItem>
          </GridContainer>
          <br />

          <GridContainer>
            <GridItem xs={0} md={2}></GridItem>
            <GridItem xs={12} md={4}>
              <Button
                fullWidth
                color="success"
                onClick={() => setEliminarModal(true)}
              >
                Cerrar Tiquete
              </Button>
            </GridItem>
            <GridItem xs={12} md={4}>
              <Button fullWidth color="danger" onClick={() => setAppBody(0)}>
                Regresar
              </Button>
            </GridItem>
          </GridContainer>
        </GridContainer>
      );
      break;
    default:
      break;
  }

  return (
    <div>
      {alert}
      <GridContainer>
        <GridItem xs={12} md={10}>
          <Card>
            <CardHeader
              className={`${classes.cardHeader} ${classes.center}`}
              color="bsci"
            >
              <GridContainer>
                <GridItem xs={12} md={8} lg={6}>
                  <img src={logoBsci} alt="Logo" width="50%" />
                </GridItem>
                <GridItem xs={12} md={4} lg={6}>
                  <h3>Consultar tiquete de avería</h3>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>{bodySelector}</CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={loadingModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setLoadingModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>Aplicación tiquetes de trabajo</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridItem xs={12}>Generando el tiquete solicitado...</GridItem>
          <GridContainer>
            <br />
            <br />
            <br />
            <GridItem xs={3}></GridItem>
            <GridItem xs={6}>
              <CircularProgress />
            </GridItem>
            <GridItem xs={3}></GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>

      {/* MODAL Login */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={loginModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setLoginModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setLoginModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Autorización</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            <GridItem xs={12} sm={4} md={3}>
              <FormLabel className={classes.labelHorizontal}>
                Ingrese su clave para autorizar este proceso:
              </FormLabel>
            </GridItem>
            <GridItem xs={12}>
              <CustomInput
                labelText="Clave"
                value={data.password}
                id="password"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: (event) => handleInputChange(event),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon className={classes.inputAdornmentIcon}>
                        lock_outline
                      </Icon>
                    </InputAdornment>
                  ),
                  type: "password",
                  autoComplete: "off",
                }}
              />
              <Danger>
                {errors.password && (
                  <div className="invalid-feedback">{errors.password}</div>
                )}
              </Danger>
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={verifyLogInSubmit} color="success">
            Ingresar
          </Button>
          <Button onClick={() => setLoginModal(false)} color="danger" simple>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* MODAL Enviar */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={eliminarModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setEliminarModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setEliminarModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Alerta</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <h3>
            ¿Está seguro de completar este reporte? Esta accion no puede
            devolverse.
          </h3>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={handleReporteTecSubmit} color="success">
            Cerrar tiquete
          </Button>
          <Button onClick={() => setEliminarModal(false)} color="danger" simple>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        place="tc"
        color="success"
        icon={AddAlert}
        message={responseMessage}
        open={tc}
        closeNotification={() => setTC(false)}
        close
      />
    </div>
  );
}
