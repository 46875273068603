import React from "react";
import axios from "axios";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

// @material-ui/icons
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import clsx from "clsx";
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardFooter from "../../components/Card/CardFooter.js";
import Danger from "../../components/Typography/Danger";

import styles from "../../assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

const useStyles = makeStyles(styles);

function getSteps() {
  return [
    "Ingresar el correo registrado",
    "Ingresar código de seguridad",
    "Crear nueva contraseña",
  ];
}

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#42ba96",
  },
  circle: {
    width: 15,
    height: 15,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#42ba96",
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

export default function NewPassword(props) {
  const initialState = {
    password: "",
    confirmPassword: "",
    isSubmitting: false,
    errorMessage: null,
  };
  const [data, setData] = React.useState(initialState);
  const [errors, setErrors] = React.useState({});
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");

  const [shown, setShown] = React.useState(false);

  const { state } = useLocation();

  let id;

  if (state) {
    id = state.id;
  }
  //const {code} = useParams();
  //const {email} = useParams();

  const [activeStep, setActiveStep] = React.useState(2);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  React.useEffect(() => {
    let id = setTimeout(function() {
      setCardAnimation("");
    }, 700);

    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  const classes = useStyles();

  const handleInputChange = (event) => {
    //onChange();
    setData({
      ...data,
      [event.target.id]: event.target.value,
    });
  };
  const verifyLogInSubmit = () => {
    let errorLocal = {};

    if (!data.password) {
      errorLocal.password = "Por favor ingresar una contraseña válida";
    }
    if (!data.confirmPassword) {
      errorLocal.confirmPassword = "Por favor ingresar una contraseña válida";
    }
    console.log(data);
    if (data.confirmPassword !== "" && data.confirmPassword !== data.password) {
      errorLocal.confirmPassword = "Las contraseñas deben coincidir";
    }
    if (data.password.length < 8 && data.confirmPassword.length < 8) {
      errorLocal.confirmPassword =
        "Las contraseñas deben tener 8 o más caracteres";
    }

    setErrors(errorLocal);
    if (Object.keys(errorLocal).length === 0) {
      handleFormSubmit();
    }
  };
  const handleFormSubmit = async (e) => {
    setData({
      ...data,
      isSubmitting: true,
      errorMessage: null,
    });

    const tempData = {
      newPass: data.password,
    };

    await axios
      .post(
        `http://localhost:5001/api/users/changepassword/${id}`,
        //`https://epstool.com/api/users/changepassword/${id}`,
        tempData
      )
      .then((res) => {
        //e.target.reset();
        notify();
        setData(initialState);
        document.getElementById("form").reset();
        handleNext();
        window.location.href = `http://localhost:3025/auth/login-page`;
        //navigate(`/new-password/${res.data.userId}`);
      })
      .catch((error) => {
        let errorLocal = {};
        errorLocal.code = "Ha ocurrido un error, por favor inténtelo de nuevo";
        setErrors(errorLocal);
      });
  };

  const switchShown = () => setShown(!shown);

  //const navigate = useNavigate();
  const history = useHistory();

  const notify = () => {
    // Set to 10sec
    toast.success("Contraseña actuaizada con éxito!", { autoClose: 3000 });
  };

  const reset = () => {
    setActiveStep(0);
    //email = "";
    id = "";
    //code = "";
    data.password = "";
    document.getElementById("form").reset();
    history.push(`/password/forgot-password`);
  };

  return (
    <div className={classes.container}>
      <GridContainer style={{ marginTop: `50px` }} justify="center">
        <Card>
          <div className={[classes.root]}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel
                      StepIconComponent={QontoStepIcon}
                      {...labelProps}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>
        </Card>
      </GridContainer>
      <br></br>
      <br></br>
      <GridContainer style={{ marginTop: `50px` }} justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form id="form">
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="success"
              >
                <h4 className={classes.cardTitle}>Paso 3</h4>
              </CardHeader>
              <CardBody>
                <div className="">Ingrese la nueva contraseña*</div>
                <CustomInput
                  labelText="Password"
                  value={data.password}
                  id="password"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) => handleInputChange(event),
                    endAdornment: (
                      // <Button onClick={switchShown}>
                      <InputAdornment position="end" onClick={switchShown}>
                        <IconButton>
                          {!shown ? (
                            <VisibilityOffIcon
                              className={classes.inputAdornmentIcon}
                            />
                          ) : (
                            <VisibilityIcon
                              className={classes.inputAdornmentIcon}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                      // </Button>
                    ),
                    type: shown ? "text" : "password",
                    autoComplete: "off",
                  }}
                />
                <Danger>
                  {errors.password && (
                    <div className="invalid-feedback">{errors.password}</div>
                  )}
                </Danger>
                <CustomInput
                  labelText="Confirm Password"
                  value={data.confirmPassword}
                  id="confirmPassword"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) => handleInputChange(event),
                    endAdornment: (
                      // <Button onClick={switchShown}>
                      <InputAdornment position="end" onClick={switchShown}>
                        <IconButton>
                          {!shown ? (
                            <VisibilityOffIcon
                              className={classes.inputAdornmentIcon}
                            />
                          ) : (
                            <VisibilityIcon
                              className={classes.inputAdornmentIcon}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                      // </Button>
                    ),
                    type: shown ? "text" : "password",
                    autoComplete: "off",
                  }}
                />
                <Danger>
                  {errors.confirmPassword && (
                    <div className="invalid-feedback">
                      {errors.confirmPassword}
                    </div>
                  )}
                </Danger>
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button color="danger" simple size="lg" block onClick={reset}>
                  Reiniciar
                </Button>
                <Button
                  color="success"
                  simple
                  size="lg"
                  block
                  onClick={verifyLogInSubmit}
                >
                  Finalizar
                </Button>
                {data.errorMessage && (
                  <span className="form-error">{data.errorMessage}</span>
                )}
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
