import AdmiForm from "views/Forms/admiForm.js";
import CerrarTiquetes from "views/Forms/cerrarTiquetes.js";
import GenerarReporte from "./views/Forms/generarReporte";
import ConfirmCode from "./views/Password/ConfirmCode";
import ForgotPassword from "./views/Password/ForgotPassword";
import NewPassword from "./views/Password/NewPassword";
import RegistrarLimpieza from "views/ServicioSanitario/registrarLimpieza";
import FormRdtt from "views/trabajosTecnicos/FormRdtt";
// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";

var dashRoutes = [
  {
    invisible: true,
    path: "/coopervisiontrabajos",
    name: "Cooper Trabajos",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: AdmiForm,
    layout: "/forms",
  },
  {
    invisible: true,
    path: "/bsccontratista/:contratista",
    name: "Tiquetes BSCI",
    icon: DashboardIcon,
    component: CerrarTiquetes,
    layout: "/forms",
  },
  {
    invisible: true,
    path: "/registrolimpieza/:nombre/:edificio/:nivel/:codigo/:tipo",
    name: "Registro Limpieza",
    icon: DashboardIcon,
    component: RegistrarLimpieza,
    layout: "/forms",
  },
  {
    invisible: true,
    path: "/reporte/:ubicacion",
    name: "Tiquetes BSC",
    icon: DashboardIcon,
    component: GenerarReporte,
    layout: "/forms",
  },
  ///New
  {
    invisible: true,
    path: "/forgot-password",
    name: "Olvide Contraseña",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: ForgotPassword,
    layout: "/password",
  },
  {
    invisible: true,
    path: "/confirm-code/:email/:id",
    name: "Confirmar Código",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: ConfirmCode,
    layout: "/password",
  },
  {
    invisible: true,
    path: "/new-password/:id",
    name: "Cambiar Contraseña",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: NewPassword,
    layout: "/password",
  },
  {
    invisible: true,
    path: "/trabajos",
    name: "Trabajos tecnicos",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: FormRdtt,
    layout: "/forms",
  },
];
export default dashRoutes;
