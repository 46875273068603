import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import FormsLayout from "layouts/Forms.js";
import ConfirmCode from "./views/Password/ConfirmCode";
import ForgotPassword from "./views/Password/ForgotPassword";
import NewPassword from "./views/Password/NewPassword";

import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";

const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/forms" component={FormsLayout} />
      <Route path="/password/forgot-password" component={ForgotPassword} />
      <Route path="/password/confirm-code" component={ConfirmCode} />
      <Route path="/password/new-password" component={NewPassword} />
      <Redirect from="/" to="/forms/coopervisiontrabajos" />
    </Switch>
  </Router>,
  document.getElementById("root")
);
