export const solicitantes = [
  {
    id: 1,
    name: "Carlos Alvarado",
    email: "Carlos.Alvarado@bsci.com",
  },
  {
    id: 2,
    name: "Carlos Arguedas",
    email: "CarlosFelipe.ArguedasRimola@bsci.com",
  },
  {
    id: 3,
    name: "Gabriela Lazo",
    email: "Gabriela.Lazo@bsci.com",
  },
  {
    id: 4,
    name: "Julio Carmiol",
    email: "Julio.Carmiol@bsci.com",
  },
  {
    id: 5,
    name: "Estaban Campos",
    email: "Esteban.CamposSanchez@bsci.com",
  },
  {
    id: 6,
    name: "Maria Pacheco",
    email: "Maria.PachecoEsquivel@bsci.com",
  },
  {
    id: 7,
    name: "ruben Contreras",
    email: "Ruben.Contreras@bsci.com",
  },
  {
    id: 8,
    name: "Andres Arroyo",
    email: "Andres.ArroyoMena@bsci.com",
  },
  {
    id: 9,
    name: "Mario Dalolio",
    email: "Mario.DalolioBarrantes@bsci.com",
  },
  {
    id: 10,
    name: "Luis Perez",
    email: "LuisMario.PerezCruz@bsci.com",
  },
  {
    id: 11,
    name: "Leonardo Calderon",
    email: "Leonardo.CalderonMurillo@bsci.com",
  },
  {
    id: 12,
    name: "Walter Molina",
    email: "Walter.MolinaVarela@bsci.com",
  },
  {
    id: 13,
    name: "Gonzalo Diaz",
    email: "GonzaloAndres.DiazObando@bsci.com",
  },
  {
    id: 14,
    name: "Edgar Campos",
    email: "Edgar.CamposMejia@bsci.com",
  },
  {
    id: 15,
    name: "jose de gouveia",
    email: "josedegouveiac@gmail.com",
  },
];
