import React, { useState, useEffect } from "react";
import axios from "axios";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import FormLabel from "@material-ui/core/FormLabel";
import AddAlert from "@material-ui/icons/AddAlert";
import Icon from "@material-ui/core/Icon";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
//Form
import CustomInput from "components/CustomInput/CustomInput.js";
import Danger from "components/Typography/Danger";
import Snackbar from "components/Snackbar/Snackbar.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import GridItem from "components/Grid/GridItem";
import Slide from "@material-ui/core/Slide";
import Close from "@material-ui/icons/Close";
import InputAdornment from "@material-ui/core/InputAdornment";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

import logoBsci from "assets/img/boston-scientific-logo.png";

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
export default function RegistrarLimpieza(props) {
  const nombre = props.match.params.nombre.replace(/_/g, " ");
  const edificio = props.match.params.edificio;
  const tipo = props.match.params.tipo;
  const nivel = props.match.params.nivel.replace(/_/g, " ");
  const [comentario, setComentario] = useState("");
  const [firma, setFirma] = useState("");
  const [informetoSend, setInformetoSend] = useState(false);
  const initialState = {
    email: "",
    password: "",
    isSubmitting: false,
    errorMessage: null,
  };
  const [flag, setFlag] = useState("");
  const [numReporte, setNumReporte] = useState("");
  const [txtClave, setTxtClave] = useState("");
  const [errors, setErrors] = useState({});
  const [tc, setTC] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [alert, setAlert] = useState(null);
  const [tiquete, setTiquete] = useState();
  const [loginModal, setLoginModal] = useState(false);
  const [data, setData] = useState(initialState);
  const [appBody, setAppBody] = useState(0);
  const [contratista, setContratista] = useState();
  const [responseMessage, setResponseMessage] = useState();
  const [eliminarModal, setEliminarModal] = useState(false);
  const [estado, setEstado] = useState({});
  const [time, setTime] = useState();
  const classes = useStyles();

  const showNotification = () => {
    setTC(true);
    setTimeout(function() {
      setTC(false);
    }, 6000);
  };

  //login
  const handleInputChange = (event) => {
    setData({
      ...data,
      [event.target.id]: event.target.value,
    });
  };

  //Login
  const verifyLogInSubmit = async () => {
    let errorLocal = {};
    !data.password
      ? (errorLocal.password = "Porfavor ingresar una clave válida")
      : setFlag(200);
    setErrors(errorLocal);
    if (Object.keys(errorLocal).length === 0) {
      await axios
        // .get(
        //   `http://localhost:5005/api/reporte/verificarContratista/${data.password}`
        // )
        .get(
          `https://epstool.com/api/reporte/verificarContratista/${data.password}`
        )
        .then((res) => {
          setLoginModal(false);
          setLoadingModal(true);
          setContratista(res.data);
          axios
            .get(
              // `http://localhost:5005/api/limpieza/externalserviciosanitario/${nombre}/${tipo}`
              `https://epstool.com/api/limpieza/externalserviciosanitario/${nombre}/${tipo}`
            )
            .then((res) => {
              setEstado(res.data);
              if (res.data.estado === 3) {
                setAppBody(2);
              } else {
                setAppBody(1);
              }
              setLoadingModal(false);
            });
        })
        .catch((error) => {
          setErrors(error.response.data);
        });
    }
  };

  const iniciarLimpieza = async (event) => {
    setEliminarModal(false);
    setLoadingModal(true);
    await axios
      // .post(`http://localhost:5005/api/limpieza/comenzarLimpieza/${estado._id}`)
      .post(`https://epstool.com/api/limpieza/comenzarLimpieza/${estado._id}`)
      .then((res) => {
        // console.log(res.data.serviciosanitario.estado);
        // estado.estado = res.data.serviciosanitario.s;
        setEstado({
          ...estado,
          estado: res.data.serviciosanitario.estado,
        });
        setResponseMessage("Informe iniciado con éxito");
        setAppBody(2);
        setLoadingModal(false);
        showNotification();
      })
      .catch((error) => {
        setErrors(error.response.data);
      });
  };

  const finalizarLimpieza = async (event) => {
    console.log(estado);
    let errorLocal = {};
    firma.length <= 3
      ? (errorLocal.firma = "Debe ingresar un nombre válido")
      : setFlag(200);
    setErrors(errorLocal);
    if (Object.keys(errorLocal).length === 0) {
      let body = { firma: firma, comentario: comentario };
      console.log(body);

      setInformetoSend(false);
      setLoadingModal(true);
      await axios
        // .put(
        //   `http://localhost:5005/api/limpieza/finalizarLimpieza/${estado._id}`,
        //   body
        // )
        .put(
          `https://epstool.com/api/limpieza/finalizarLimpieza/${estado._id}`,
          body
        )
        .then((res) => {
          setEstado({
            ...estado,
            estado: res.data.serviciosanitario.estado,
            ultimaLimpieza: res.data.serviciosanitario.ultimaLimpieza,
          });
          setResponseMessage("Informe finalizado con éxito");
          setAppBody(1);
          setLoadingModal(false);
          showNotification();
        })
        .catch((error) => {
          setErrors(error.response.data);
        });
    }
  };

  let bodySelector;
  switch (appBody) {
    case 0:
      bodySelector = (
        <GridContainer>
          <GridContainer>
            <GridItem xs={12} className={classes.center}>
              <h3>
                Baño {nombre} de {tipo}
              </h3>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={4} md={3}>
              Ubicación:
            </GridItem>
            <GridItem xs={8} md={9}>
              {nombre}
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={4} md={3}>
              Edificio:
            </GridItem>
            <GridItem xs={8} md={9}>
              {edificio}
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={4} md={3}>
              Nivel:
            </GridItem>
            <GridItem xs={8} md={9}>
              {nivel}
            </GridItem>
          </GridContainer>
          <br />
          <GridContainer>
            <GridItem xs={0} md={2}></GridItem>
            <GridItem xs={12} md={4}>
              <Button
                fullWidth
                color="success"
                onClick={() => setLoginModal(true)}
              >
                Control de limpieza
              </Button>
            </GridItem>
            <GridItem xs={12} md={4}>
              {/* // props.history.push(`serviciosanitario/${servicio.nombre}`); */}

              <Button
                fullWidth
                color="success"
                onClick={() =>
                  props.history.push(
                    `../../../../../reporte/baño_${nombre}_${edificio}`
                  )
                }
              >
                Reporte de averia
              </Button>
            </GridItem>
          </GridContainer>
        </GridContainer>
      );
      break;
    case 1:
      if (contratista) {
        bodySelector = (
          <GridContainer>
            <GridContainer>
              <GridItem xs={12} className={classes.center}>
                <h3>
                  Baño {nombre} de {tipo}
                </h3>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={4} md={3}>
                Ubicación:
              </GridItem>
              <GridItem xs={8} md={9}>
                {nombre}
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={4} md={3}>
                Edificio:
              </GridItem>
              <GridItem xs={8} md={9}>
                {edificio}
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={4} md={3}>
                Nivel:
              </GridItem>
              <GridItem xs={8} md={9}>
                {nivel}
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={4} md={3}>
                Estado:
              </GridItem>
              <GridItem xs={8} md={9}>
                {estado
                  ? estado.estado === 1
                    ? "Habilitado"
                    : estado.estado === 2
                    ? "Fuera de servicio"
                    : estado.estado === 3
                    ? "En Limpieza"
                    : "No disponible"
                  : "No disponible"}
              </GridItem>
            </GridContainer>
            {estado && estado.ultimaLimpieza ? (
              <GridContainer>
                <GridItem xs={4} md={3}>
                  Ultima Limpieza:
                </GridItem>
                <GridItem xs={8} md={9}>
                  {new Date(estado.ultimaLimpieza * 1000).toLocaleString()}
                </GridItem>
              </GridContainer>
            ) : (
              ""
            )}
            <br></br>
            <GridContainer>
              <GridItem xs={12} className={classes.center}>
                <h4>Proceso de limpieza</h4>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <ArrowForwardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Tocar la puerta y asegurarse que no haya nadie" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <ArrowForwardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Barricar el baño" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <ArrowForwardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Escanear código QR para comenzar la rutina de baño" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <ArrowForwardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Colocar el desinfectante en las superficies de orinales, inodoros y lavamanos" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <ArrowForwardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Revisar suministros (papel higienico, jabon)" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <ArrowForwardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Barrer el piso" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <ArrowForwardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Restregar con hisopo inodoros y orinales" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <ArrowForwardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Limpiar lavamos y espejos" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <ArrowForwardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Limpiar patogenos si existen" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <ArrowForwardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Limpiar divisiones y paredes" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <ArrowForwardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Pasar el palo piso con quimico y esperar a que seque" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <ArrowForwardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Utilizar el 2+2 para verificar que todo este en orden" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <ArrowForwardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Escanear código QR para finalizar la rutina de bano." />
                  </ListItem>
                </List>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={0} md={2}></GridItem>
              <GridItem xs={12} md={4}>
                <Button
                  fullWidth
                  color="success"
                  onClick={() => setEliminarModal(true)}
                >
                  Comenzar Limpieza
                </Button>
              </GridItem>
              <GridItem xs={12} md={4}>
                <Button
                  fullWidth
                  color="success"
                  onClick={() =>
                    window.open(
                      "https://cdn.filestackcontent.com/0vxXhhfcRwmGi93V2oIw",
                      "_blank"
                    )
                  }
                >
                  Descargar manual
                </Button>
              </GridItem>
              <GridItem xs={12} md={4}>
                <Button fullWidth color="danger" onClick={() => setAppBody(0)}>
                  Regresar
                </Button>
              </GridItem>
            </GridContainer>
          </GridContainer>
        );
      } else {
        setAppBody(0);
      }
      break;
    case 2:
      if (contratista) {
        bodySelector = (
          <GridContainer>
            <GridContainer>
              <GridItem xs={12} className={classes.center}>
                <h3>
                  Baño {nombre} de {tipo}
                </h3>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={4} md={3}>
                Estado:
              </GridItem>
              <GridItem xs={8} md={9}>
                {estado
                  ? estado.estado === 1
                    ? "Habilitado"
                    : estado.estado === 2
                    ? "Fuera de servicio"
                    : estado.estado === 3
                    ? "En Limpieza"
                    : "No disponible"
                  : "No disponible"}
              </GridItem>
            </GridContainer>
            {estado && estado.ultimaLimpieza ? (
              <GridContainer>
                <GridItem xs={4} md={3}>
                  Ultima Limpieza:
                </GridItem>
                <GridItem xs={8} md={9}>
                  {new Date(estado.ultimaLimpieza * 1000).toLocaleString()}
                </GridItem>
              </GridContainer>
            ) : (
              ""
            )}
            <br></br>
            <GridContainer>
              <GridItem xs={12} className={classes.center}>
                <h4>Proceso de limpieza</h4>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <ArrowForwardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Tocar la puerta y asegurarse que no haya nadie" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <ArrowForwardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Barricar el baño" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <ArrowForwardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Palo piso con líquido Virex o Crew" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <ArrowForwardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Bajar la palanca de los orinales e inodoros" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <ArrowForwardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Echar líquido Virex o Crew en la parte interna de los orinales e inodoros" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <ArrowForwardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Sacar la basura y limpiar los basureros" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <ArrowForwardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Limpiar los desechos corporales en las divisiones y paredes" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <ArrowForwardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Colocar todos los suministros que sean necesarios (papel, toallas, jabon, etc)" />
                  </ListItem>
                </List>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={0} md={2}></GridItem>
              <GridItem xs={12} md={4}>
                <Button
                  fullWidth
                  color="success"
                  onClick={() => setInformetoSend(true)}
                >
                  Finalizar Limpieza
                </Button>
              </GridItem>
              <GridItem xs={12} md={4}>
                <Button fullWidth color="danger" onClick={() => setAppBody(0)}>
                  Regresar
                </Button>
              </GridItem>
            </GridContainer>
          </GridContainer>
        );
      } else {
        setAppBody(0);
      }
      break;
    default:
      break;
  }

  return (
    <div>
      {alert}
      <GridContainer>
        <GridItem xs={12} md={10}>
          <Card>
            <CardHeader
              className={`${classes.cardHeader} ${classes.center}`}
              color="bsci"
            >
              <GridContainer>
                <GridItem xs={12} md={8} lg={6}>
                  <img src={logoBsci} alt="Logo" width="50%" />
                </GridItem>
                <GridItem xs={12} md={4} lg={6}>
                  <h3>Registro de Limpieza</h3>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>{bodySelector}</CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={loadingModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setLoadingModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>Aplicación tiquetes de trabajo</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridItem xs={12}>Generando el tiquete solicitado...</GridItem>
          <GridContainer>
            <br />
            <br />
            <br />
            <GridItem xs={3}></GridItem>
            <GridItem xs={6}>
              <CircularProgress />
            </GridItem>
            <GridItem xs={3}></GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>

      {/* MODAL Login */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={loginModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setLoginModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setLoginModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Autorización</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <FormLabel className={classes.labelHorizontal}>
                Ingrese su clave para autorizar este proceso:
              </FormLabel>
            </GridItem>
            <GridItem xs={12} md={8}>
              <CustomInput
                labelText="Clave"
                value={data.password}
                id="password"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: (event) => handleInputChange(event),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon className={classes.inputAdornmentIcon}>
                        lock_outline
                      </Icon>
                    </InputAdornment>
                  ),
                  type: "password",
                  autoComplete: "off",
                }}
              />
              <Danger>
                {errors.password && (
                  <div className="invalid-feedback">{errors.password}</div>
                )}
              </Danger>
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={verifyLogInSubmit} color="success">
            Ingresar
          </Button>
          <Button onClick={() => setLoginModal(false)} color="danger" simple>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* MODAL Enviar */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={eliminarModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setEliminarModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setEliminarModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Alerta</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <h3>
            ¿Está seguro de iniciar la limpieza? Esta acción no puede
            devolverse.
          </h3>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          {estado && estado.estado === 3 ? (
            <Button onClick={finalizarLimpieza} color="success">
              Finalizar
            </Button>
          ) : (
            <Button onClick={iniciarLimpieza} color="success">
              Comenzar
            </Button>
          )}
          <Button onClick={() => setEliminarModal(false)} color="danger" simple>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* MODAL Login */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={informetoSend}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setInformetoSend(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setInformetoSend(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Autorización</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <FormLabel className={classes.labelHorizontal}>
                Firme con su nombre:*
              </FormLabel>
            </GridItem>
            <GridItem xs={12} md={8}>
              <CustomInput
                labelText="Firma"
                value={firma}
                id="firma"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: (event) => setFirma(event.target.value),
                  type: "text",
                  autoComplete: "off",
                }}
              />
              <Danger>
                {errors.firma && (
                  <div className="invalid-feedback">{errors.firma}</div>
                )}
              </Danger>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <FormLabel className={classes.labelHorizontal}>
                Comentario:
              </FormLabel>
            </GridItem>
            <GridItem xs={12} md={8}>
              <CustomInput
                labelText="Comentario"
                value={comentario}
                id="comentario"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  multiline: true,
                  onChange: (event) => setComentario(event.target.value),
                  type: "text",
                  autoComplete: "off",
                }}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={finalizarLimpieza} color="success">
            Enviar
          </Button>
          <Button onClick={() => setLoginModal(false)} color="danger" simple>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        place="tc"
        color="success"
        icon={AddAlert}
        message={responseMessage}
        open={tc}
        closeNotification={() => setTC(false)}
        close
      />
    </div>
  );
}
