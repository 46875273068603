import React, { useState, useEffect } from "react";
import axios from "axios";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { PickerDropPane } from "filestack-react";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import FormLabel from "@material-ui/core/FormLabel";
import AddAlert from "@material-ui/icons/AddAlert";
import Datetime from "react-datetime";
//Form
import CustomInput from "components/CustomInput/CustomInput.js";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Danger from "components/Typography/Danger";
import Snackbar from "components/Snackbar/Snackbar.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import GridItem from "components/Grid/GridItem";
import Slide from "@material-ui/core/Slide";
import SweetAlert from "react-bootstrap-sweetalert";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import ImageSearchIcon from "@material-ui/icons/ImageSearch";

import logoCooper from "assets/img/cooperTransp.png";

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
export default function AdmiForm(props) {
  const API_KEY = "AWevYlMNkS7KJFbiRivemz";

  const [desde, setDesde] = useState(
    new Date().setDate(new Date().getDate() + 7)
  );
  const [files, setFiles] = useState([]);
  const [flag, setFlag] = useState("");
  const [tecnico, setTecnico] = useState("");
  const [categoria, setCategoria] = useState("");
  const [urlFile, setUrlFile] = useState([]);
  const [urlFileError, setUrlFileError] = useState();
  const [txtComentario, setTxtComentario] = React.useState("");
  const [txtZona, setTxtZona] = React.useState("");
  const [errors, setErrors] = React.useState({});
  const [tc, setTC] = React.useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [alert, setAlert] = React.useState(null);

  const classes = useStyles();

  const successAlert = (codigo, tecnico) => {
    const tittleText = `Reporte ${codigo} realizado`;
    setAlert(
      <SweetAlert
        success
        // style={{ display: "block" }}
        style={{ display: "block", marginTop: "100px" }}
        title={tittleText}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        timeout={2000}
        // confirmBtnBsStyle="success"
        showCloseButton
        confirmBtnBsStyle={"secondary"}
        // confirmBtnStyle={classes.button + " " + classes.success}
      >
        Se ha asignado un nuevo reporte a {tecnico}
      </SweetAlert>
    );
  };
  const hideAlert = () => {
    setAlert(null);
  };

  const showNotification = () => {
    setTC(true);
    setTimeout(function() {
      setTC(false);
    }, 6000);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let errorMessage = {};
    !txtComentario
      ? (errorMessage.comentario = "Debe escribir el comentario del tiquete")
      : setFlag(200);
    !tecnico
      ? (errorMessage.tecnico = "Debe selecionar un técnico")
      : setFlag(200);
    !categoria
      ? (errorMessage.categoria = "Debe selecionar una categoría")
      : setFlag(200);
    !desde
      ? (errorMessage.fecha = "Debe selecionar una fecha valida")
      : setFlag(200);

    setErrors(errorMessage);

    if (Object.keys(errorMessage).length === 0) {
      setLoadingModal(true);

      let desdeLocal = desde;
      if (typeof desde === "object") {
        desdeLocal = desde.unix();
      } else {
        desdeLocal = desde / 1000;
      }
      const reporteToSave = {
        comentario: txtComentario,
        lugar: txtZona,
        tecnico: tecnico,
        categoria: categoria,
        fecha: Math.trunc(desdeLocal),
        files: files,
      };
      await axios
        // .post(
        //   `https://epstool.com/api/tiquetes/trabajo/nuevoreporte`,
        //   reporteToSave
        // )
        .post("/api/tiquetes/trabajo/nuevoreporte", reporteToSave)
        .then((res) => {
          console.log(res);
          setErrors({});
          setTxtComentario("");
          setTxtZona("");
          setTecnico("");
          setCategoria("");
          setDesde(new Date().setDate(new Date().getDate() + 7));
          setFiles([]);
          // showNotification();
          setLoadingModal(false);
          successAlert(
            res.data.reporte.codigoReporte,
            res.data.reporte.tecnicoAsignado.email
          );
        })
        .catch((error) => {
          setErrors(error.response.data);
        });
    }
  };

  const handleListFiles = async (uploadedFiles) => {
    uploadedFiles.forEach((element) => {
      console.log(element);

      setFiles((prevFiles) => [...prevFiles, element]);
    });
  };

  const handleDeleteFile = async (idFileToDelete) => {
    setFiles(files.filter((item) => item.uploadId !== idFileToDelete));
  };

  return (
    <div>
      {alert}
      <GridContainer>
        <GridItem xs={12} md={10}>
          <Card>
            <CardHeader
              className={`${classes.cardHeader} ${classes.center}`}
              color="info"
            >
              <h3>Crear nuevo tiquete de trabajo</h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={4} md={3}>
                  <FormLabel className={classes.labelHorizontal}>
                    Reporte:
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={8} md={9}>
                  <CustomInput
                    multiline
                    labelText="Comentario"
                    id="txtComentario"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      multiline: true,
                      onChange: (event) => {
                        setTxtComentario(event.target.value);
                      },
                      type: "text",
                      value: txtComentario,
                    }}
                  />
                  <Danger>
                    {errors.comentario && (
                      <div className="invalid-feedback">
                        {errors.comentario}
                      </div>
                    )}
                  </Danger>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={4} md={3}>
                  <FormLabel className={classes.labelHorizontal}>
                    Técnico:
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={8} md={9}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="multiple-select"
                      className={classes.selectLabel}
                    >
                      Selecione técnico a asignar
                    </InputLabel>
                    <Select
                      value={tecnico}
                      // onChange={(e) => handleMaquinas(e.target.value)}
                      onChange={(e) => setTecnico(e.target.value)}
                      MenuProps={{ className: classes.selectMenu }}
                      classes={{ select: classes.select }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                      >
                        Selecione
                      </MenuItem>

                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple,
                        }}
                        value={"acorrales@coopervision.com"}
                      >
                        Adrian Corrales
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple,
                        }}
                        value={"aledezmafernandez@coopervision.com"}
                      >
                        Arian Ledezma
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple,
                        }}
                        value={"droqueroque@coopervision.com"}
                      >
                        Douglas Roque
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple,
                        }}
                        value={"jrivassolis@coopervision.com"}
                      >
                        Eduardo Rivas
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple,
                        }}
                        value={"jmendozafonseca@coopervision.com"}
                      >
                        Juan D. Mendoza
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple,
                        }}
                        value={"wesquivelsolorzano@coopervision.com"}
                      >
                        William Esquivel
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple,
                        }}
                        value={"htrujillolopez@coopervision.com"}
                      >
                        Henry Trujillo
                      </MenuItem>
                    </Select>
                    <Danger>
                      {errors.tecnico && (
                        <div className="invalid-feedback">{errors.tecnico}</div>
                      )}
                    </Danger>
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={4} md={3}>
                  <FormLabel className={classes.labelHorizontal}>
                    Categoría:
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={8} md={9}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="multiple-select"
                      className={classes.selectLabel}
                    >
                      Selecione categoría del reporte
                    </InputLabel>
                    <Select
                      value={categoria}
                      // onChange={(e) => handleMaquinas(e.target.value)}
                      onChange={(e) => setCategoria(e.target.value)}
                      MenuProps={{ className: classes.selectMenu }}
                      classes={{ select: classes.select }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                      >
                        Selecione
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple,
                        }}
                        value={"Facilidades"}
                      >
                        Facilidades
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple,
                        }}
                        value={"Utilidades"}
                      >
                        Utilidades
                      </MenuItem>
                    </Select>
                    <Danger>
                      {errors.categoria && (
                        <div className="invalid-feedback">
                          {errors.categoria}
                        </div>
                      )}
                    </Danger>
                  </FormControl>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={4} md={3}>
                  <FormLabel className={classes.labelHorizontal}>
                    Ubicación:
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={8} md={9}>
                  <CustomInput
                    multiline
                    labelText="Zona"
                    id="txtZona"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      multiline: true,
                      onChange: (event) => {
                        setTxtZona(event.target.value);
                      },
                      type: "text",
                      value: txtZona,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={4} md={3}>
                  <FormLabel className={classes.labelHorizontal}>
                    Archivos:
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={8} md={9}>
                  <PickerDropPane
                    apikey={API_KEY}
                    onUploadDone={(res) => handleListFiles(res.filesUploaded)}
                    pickerOptions={{ maxFiles: 5 }}
                    onError={(error) =>
                      setUrlFileError(
                        "Se ha producido un error, porfavor intentarlo nuevamente"
                      )
                    }
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={4} md={3}>
                  <FormLabel className={classes.labelHorizontal}>
                    Archivos Cargados:
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={8} md={9}>
                  <List>
                    {files.length === 0 ? (
                      <p className={classes.labelHorizontalIzq}>
                        No hay archivos cargados
                      </p>
                    ) : (
                      files.map((file) => (
                        <ListItem dense key={file.uploadId}>
                          <ListItemText primary={file.filename} />
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              aria-label="open"
                              onClick={() => window.open(file.url, "_blank")}
                            >
                              <ImageSearchIcon />
                            </IconButton>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => handleDeleteFile(file.uploadId)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))
                    )}
                  </List>
                </GridItem>
              </GridContainer>
              <br />

              <GridContainer>
                <GridItem xs={12} sm={4} md={3}>
                  <FormLabel className={classes.labelHorizontal}>
                    Fecha:
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={8} md={9}>
                  <Datetime
                    onChange={(value) => setDesde(value)}
                    className={classes.labelHorizontalFecha}
                    timeFormat={false}
                    closeOnSelect={true}
                    value={desde}
                  />
                  <Danger>
                    {errors.fecha && (
                      <div className="invalid-feedback">{errors.fecha}</div>
                    )}
                  </Danger>
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer>
                <GridItem xs={0} md={3}></GridItem>
                <GridItem xs={12} md={9}>
                  <Button fullWidth color="success" onClick={handleSubmit}>
                    Crear reporte
                  </Button>
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer>
                <GridItem xs={0} md={3}></GridItem>
                <GridItem xs={12} md={9} lg={6} className={`${classes.center}`}>
                  <img src={logoCooper} alt="Logo" width="50%" />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={loadingModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setLoadingModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>Aplicación tiquetes de trabajo</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridItem xs={12}>Generando el tiquete solicitado...</GridItem>
          <GridContainer>
            <br />
            <br />
            <br />
            <GridItem xs={3}></GridItem>
            <GridItem xs={6}>
              <CircularProgress />
            </GridItem>
            <GridItem xs={3}></GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>

      <Snackbar
        place="tc"
        color="success"
        icon={AddAlert}
        message="Reporte creado con éxito"
        open={tc}
        closeNotification={() => setTC(false)}
        close
      />
    </div>
  );
}
