import React from "react";
import axios from "axios";
import { useParams, useHistory, useLocation } from "react-router-dom"
import clsx from 'clsx';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

// @material-ui/icons
import { LockOutlined } from "@material-ui/icons";
import Check from '@material-ui/icons/Check';

// core components
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardFooter from "../../components/Card/CardFooter.js";
import Danger from "../../components/Typography/Danger";

import styles from "../../assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

const useStyles = makeStyles(styles);

function getSteps() {
    return ['Ingresar el correo registrado', 'Ingresar código de seguridad', 'Crear nueva contraseña'];
}

const useQontoStepIconStyles = makeStyles({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    active: {
        color: '#42ba96',
    },
    circle: {
        width: 15,
        height: 15,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
    completed: {
        color: '#42ba96',
        zIndex: 1,
        fontSize: 18,
    },
});

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
        </div>
    );
}

export default function ConfirmCode(props) {

    const { state } = useLocation();
    let id;
    let email;

    if (state) {
        id = state.id;
        email = state.email;
    }

    let initialState = {
        code: "",
        isSubmitting: false,
        errorMessage: null,
    };

    const [data, setData] = React.useState(initialState);
    const [errors, setErrors] = React.useState({});
    const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");


    React.useEffect(() => {
        let id = setTimeout(function () {
            setCardAnimation("");
        }, 700);
        // Specify how to clean up after this effect:
        return function cleanup() {
            window.clearTimeout(id);
        };
    });

    const [activeStep, setActiveStep] = React.useState(1);
    const [skipped, setSkipped] = React.useState(new Set());
    const steps = getSteps();

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    React.useEffect(() => {
        let id = setTimeout(function () {
            setCardAnimation("");
        }, 700);
        // Specify how to clean up after this effect:
        return function cleanup() {
            window.clearTimeout(id);
        };
    });

    const classes = useStyles();

    const handleInputChange = (event) => {
        setData({
            ...data,
            [event.target.id]: event.target.value,
        });
    };

    const verifyLogInSubmit = () => {
        let errorLocal = {};
        if (!data.code) {
            errorLocal.code = "Por favor ingresar un código válido";
        }
        setErrors(errorLocal);
        if (Object.keys(errorLocal).length === 0) {
            handleFormSubmit();
        }
    };

    const handleFormSubmit = async () => {
        setData({
            ...data,
            isSubmitting: true,
            errorMessage: null,
        });

        const tempData = {
            code: data.code,
        }

        await axios.post(
            `http://localhost:5001/api/users/confirmcode/${id}`,
            //`https://epstool.com/api/users/confirmcode/${id}`,
            tempData
        ).then((res) => {
            notify();
            setData(initialState);
            handleNext();
            history.push({ pathname: `/password/new-password`, state: { id: res.data.userId } });
        })
            .catch((error) => {
                let errorLocal = {};
                errorLocal.code = "El código ingresado no coincide, por favor ingresar un código válido";
                setErrors(errorLocal);
            });
    };

    //const navigate = useNavigate();
    const history = useHistory();

    const notify = () => {
        // Set to 10sec
        toast.success('Código verificado!', { autoClose: 3000 })
    }

    const goback = () => {
        history.push({ pathname: `/password/forgot-password`, state: { email, id } });
    }

    const reset = () => {
        setActiveStep(0);
        email = "";
        id = "";
        data.code = '';
        document.getElementById("form").reset();
        history.push(`/password/forgot-password`);
    }

    return (
        <div style={{ marginTop: `50px` }} className={classes.container}>
            <div className={classes.root}>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel StepIconComponent={QontoStepIcon} {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </div>
            <br></br>
            <br></br>
            <GridContainer style={{ marginTop: `50px` }} justify="center">
                <GridItem xs={12} sm={6} md={4}>
                    <form id="form">
                        <Card login className={classes[cardAnimaton]}>
                            <CardHeader
                                className={`${classes.cardHeader} ${classes.textCenter}`}
                                color="success"
                            >
                                <h4 className={classes.cardTitle}>Paso 2</h4>
                            </CardHeader>
                            <CardBody>
                                <div className="">Se le ha enviado un código de seguridad de 6 dígitos a su correo, favor ingresarlo</div>
                                <CustomInput
                                    labelText="Ingrese su código*"
                                    id="code"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        type: "text",
                                        value: data.code,
                                        onChange: (event) => handleInputChange(event),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <LockOutlined className={classes.inputAdornmentIcon} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Danger>
                                    {errors.code && (
                                        <div className="invalid-feedback">{errors.code}</div>
                                    )}
                                </Danger>
                            </CardBody>
                            <CardFooter className={classes.justifyContentCenter}>
                                <Button
                                    color="warning"
                                    simple
                                    size="lg"
                                    block
                                    onClick={goback}
                                >
                                    Anterior
                                </Button>
                                <Button
                                    color="danger"
                                    simple
                                    size="lg"
                                    block
                                    onClick={
                                        reset
                                    }
                                >
                                    Reiniciar
                                </Button>
                                <Button
                                    color="success"
                                    simple
                                    size="lg"
                                    block
                                    onClick={
                                        verifyLogInSubmit
                                    }
                                >
                                    Siguiente
                                </Button>
                                {data.errorMessage && (
                                    <span className="form-error">{data.errorMessage}</span>
                                )}
                            </CardFooter>
                        </Card>
                    </form>
                </GridItem>
            </GridContainer>
        </div>
    );
}